// Global constants
const Globals = {
  // For Wordpress blog page creation
  blogURI: 'blog',
  // For use with gatsby-background-image
  backgroundOverlay:
    'linear-gradient(rgba(255,255,255, 0.67), rgba(255,255,255, 0.67))',
};

module.exports = Globals;
